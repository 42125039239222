import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PaymentsImage1 from '../images/Payments-Image-1.svg'
import PaymentsImage2 from '../images/Payments-Image-2.png'
import Accordion from '../components/Accordion';

const Option = styled.div`
    background-color: white;
    width:400px;
    height: 100px;
    justify-items: center;
    align-items: center;
    display: grid;
    grid-gap:15px;
    padding: 0 30px;
    margin-bottom: 30px;
    grid-template-columns: auto 1fr;
`

const Button = styled.button`
  color: white;
  background-color: #0077AF;
  font-weight:500;
  height: 40px;
  width: 160px; 
  border: 1px solid transparent; 
  font-Size: 14px;
  align-self: center;
`

const PaymentsPage = () => (
  <Layout>
    <SEO title="Payments" />
    <section style={{ "height": "280px", "backgroundColor": "white", "padding": "104px 80px 0px 80px", "display": "grid", "gridTemplateColumns": "1fr 1fr", width: "1024px", "margin": "0 auto" }}>
      <div>
        <h1 style={{fontSize: "32px", paddingTop: "16px"}}>Payments</h1>
        <p style={{ paddingTop: "20px", width: "434px", marginBottom: "32px", lineHeight: "25px", fontSize: "18px" }}>Our simple and secure payments solution to receive and make payments</p>
        <Button id="on-hover-btn" style={{ width: "190px", borderRadius:"1.5px" }}>Request access</Button>
      </div>
      <div>
        <img style={{fontSize: "32px", paddingTop: "32px"}} src={PaymentsImage1} />
      </div>
    </section>

    <div style={{ backgroundColor: "#FBFBFB" }}>


      <section style={{ backgroundColor: "#FBFBFB", "padding": "80px 0px 50px", display: "grid", gridTemplateColumns: "1fr 1fr", width: "1024px", "margin": "0 auto" }}>
        <div>
          <h1 style={{ "fontSize": "24px", "fontWeight": "500", "color": "black" }}>What you can do</h1>
          <p style={{ paddingTop: "30px", paddingBottom: "30px", color: "#5E5E5E", fontSize: "18px", width: "410px", lineHeight:"24px" }}>Manage your invoices for each cases</p>
          <section>
          <Option>
            <h1 style={{ "color": "#124151", "fontSize": "50px", fontWeight: "300" }}>1</h1>
            <p style={{ color: "#124151", fontSize: "16px", lineHeight: "24px" }}>Make payments or get paid fast using our secure payments solutions </p>
          </Option>
          <Option>
            <h1 style={{ "color": "#124151", "fontSize": "50px", fontWeight: "300" }}>2</h1>
            <p style={{ color: "#124151", fontSize: "16px", lineHeight: "24px" }}>View how much you've earned as an attorney or how much you've spent as a client</p>
          </Option>
          </section>


          {/* <Button style={{ width: "167px", fontWeight: "600", backgroundColor: "white", color: "#0077af", border: "1px solid #0077af", borderRadius: "1.5px" }}>Request access</Button> */}
        </div>
        <div>
          <img style={{ width: "526px" }} src={PaymentsImage2} />


        </div>
      </section>
    </div>

    <section style={{ width: "1024px", "margin": "0 auto", paddingTop: "56px", paddingBottom: "56px" }}>
      <h1 style={{fontWeight: "500"}}>How it Works</h1>
      <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", listStyle: "none", padding: "0px", justifyItems: "center" }}>
        <li style={{ width: "179px" }}>
          <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700" }}>1</h1>
          <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Upload invoice</h2>
          <p style={{ fontSize: "16px", marginTop:"8px" }} >Attorney uploads their invoice in our system</p>
        </li>
        <li style={{ width: "209px" }}>
          <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "80%" }}>2</h1>
          <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Simply pay Juristerra</h2>
          <p style={{ fontSize: "16px", marginTop:"8px" }}>Client clicks on a project they wish to pay</p>
        </li>
        <li style={{ width: "203px" }}>
          <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "70%" }}>3</h1>
          <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>That’s it!</h2>
          <p style={{ fontSize: "16px", marginTop:"8px" }}>Attorney gets paid and clients get their projects completed.</p>
        </li>

      </ul>

    </section>
    <div style={{ backgroundColor: "#fbfbfb" }}>

      <section style={{ width: "1024px", "margin": "0 auto", "paddingBottom": "42px" }}>
        <div style={{ textAlign: "center" }}>
          <h1 style={{
            paddingTop: "40px", 
            paddingBottom: "40px",
            height: "32px",
            fontFamily: "Yantramanav",
            fontSize: "24px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',

            color: '#121212'
          }}>Frequently asked questions</h1>

        </div>
        {/* <Accordion title="How do you guarantee payments to attorneys?"
          content="We guarantee attorney payments by us paying attorneys for the work they have done. (i.e. Clients pay us and we pay attorneys) If clients don’t pay us, we still pay attorneys, so attorneys don’t need to worry about not getting paid by clients. We understand hard work and attorney’s hard work will be recognized and paid. " /> */}
        {/* <Accordion title="What happens if clients doesn’t Juristerra?"
          content="" /> */}

        <Accordion title="How do we know if your payments solution is safe?"
          content="We use a secure payment system that ensures your information are encrypted." />
        {/* <Accordion title="What kind of payments do you accept?"
          content="We credit cards." /> */}
        <Accordion style={{ backgroundColor: "white" }} title="As an attorney, where does my money go after getting paid?"
            content="Your money will be transferred to your direct deposit."
        />   
         <Accordion style={{ backgroundColor: "white" }} title="As an client, how do I pay for projects and legal project manager?"
            content="You make payments via credit card."
        />   
        {/* <Accordion title="What happens if an attorney claims they didn’t receive a payment and contacts client?"
          content="" /> */}
        {/* <Accordion title="How will clients know if Juristerra paid the attorney?"
          content="" /> */}

        <div style={{ "marginTop": "50px", textAlign: "center" }}>
          <p style={{
            fontFamily: "Yantramanav",
            fontSize: "16px",
            fontWeight: "500",
            paddingBottom: "50px"
          }}>Have more unanswered questions? Feel free to contact us via email at <a href="mailto:contact@juristerra.net" style={{ color: "#3eb0b4", textDecoration: "none" }}>contact@juristerra.net </a>or call us at <a href="tel:+11231231232" style={{ color: "#3eb0b4", textDecoration: "none" }}>(123) 123-1232</a></p>
        </div>

      </section>
    </div>
  </Layout>
)

export default PaymentsPage
